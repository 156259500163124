import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    report: {},
    isShowLoading: true,
    radar_img_src: undefined, // 雷达图生成的图片
    is_show_radar: 'yes', // 是否展示echart雷达图
    solar_img_src: undefined, // 雷达图生成的图片
    is_show_solar: 'yes', // 是否展示echart雷达图
    is_show_detail: false, // 是否展示产品详情
    current_product: null, // 当前展示的产品详情
  },
  mutations: {
    SET_REPORT(state, payload) { // 更新report数据
      state.report = { ...payload };
    },
    SET_LOADING(state, payload) {
      state.isShowLoading = payload
    },
    SET_RADAR_IMG(state, payload) { // echarts生成的雷达图对应的base64格式图片src
      state.radar_img_src = payload;
    },
    SET_RADAR_SHOW(state, payload) { // 是否展示原始的雷达图，yes展示，no替换为图片
      state.is_show_radar = payload;
    },
    SET_SOLAR_IMG(state, payload) { // echarts生成的雷达图对应的base64格式图片src
      state.solar_img_src = payload;
    },
    SET_SOLAR_SHOW(state, payload) { // 是否展示原始的雷达图，yes展示，no替换为图片
      state.is_show_solar = payload;
    },
    SET_DETAIL_SHOW(state,payload) { // 是否展示产品详情
      state.is_show_detail = payload; ;
    },
    SET_CURRENT_PRODUCT(state,payload) { // 当前展示的产品详情
      state.current_product = payload; ;
    },
  },
  actions: {
  },
  modules: {
  }
})
