export default {
  seriesData: function(seriesString) {
    if(seriesString == '阳虚质'){
      return [30,29,27,24,20,15,10,9,8,7,6,5,5,7,10,15,20,27,33,38,43,45,43,40]
    } else if(seriesString == '阴虚质'){
      return [10,12,15,19,24,29,34,40,43,45,43,42,39,36,20,18,16,14,12,10,8,7,6,5]
    } else if(seriesString == '气虚质'){
      return [35,40,43,45,43,40,41,43,44,45,44,45,40,35,30,28,30,32,37,42,44,45,44,40]
    } else if(seriesString == '气郁质'){
      return [25,23,20,17,15,10,11,13,15,18,21,24,27,29,30,31,34,37,41,43,44,45,44,40]
    } else if(seriesString == '血瘀质'){
      return [35,33,31,28,25,22,19,17,16,15,14,10,12,15,19,24,30,35,39,42,43,45,43,40]
    } else if(seriesString == '痰湿质'){
      return [20,21,23,26,30,34,37,40,43,44,45,44,43,41,38,35,32,29,28,27,26,25,21,20]
    } else if(seriesString == '湿热质'){
      return [10,12,14,18,24,28,32,36,40,45,45,45,42,39,36,20,19,17,14,13,12,11,10,8]
    } else if(seriesString == '特禀质'){
      return [35,40,43,45,43,35,37,35,30,25,30,25,20,18,35,45,42,40,38,36,34,33,32,31]
    } else { //平和质
      return []
    }
  }
}