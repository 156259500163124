import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pageResponse from '../utils/pageResponse'
import { queryObject } from '../utils/common.js'
import '../utils/vant.js'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)
// import VConsole from 'vconsole';
// new VConsole();


import utils from '../utils/util.js'
Vue.prototype.utils = utils

function pageResize() {
  const { is_print = '' } = queryObject();
  const isChronicPrintPage = window.location.href.includes('chronic_print');
  const isPulsePrintPage = window.location.href.includes('pulse_print');
  const printPage = is_print === '1' || isChronicPrintPage || isPulsePrintPage; // 健康、体质 || 慢病（打印报告）

  const width = printPage ? 800 : 1080;
  pageResponse({
    selectors: '#app',
    mode: 'auto',
    width,
    height: '1920'
  });
}
window.onload = pageResize

window.onresize = pageResize

Vue.config.productionTip = false; // 设置为 false 以阻止 vue 在启动时生成生产提示。

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
