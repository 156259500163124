import Vue from 'vue'

import {
  Circle, Icon, Loading, Toast, Tab, Tabs, ImagePreview
} from 'vant'

import 'vant/lib/circle/style';
import 'vant/lib/icon/style';
import 'vant/lib/loading/style';
import 'vant/lib/toast/style';
import 'vant/lib/tab/style';
import 'vant/lib/tabs/style';
import 'vant/lib/image-preview/style';

Vue.use(Circle);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Toast);
Vue.use(Tab).use(Tabs);
Vue.use(ImagePreview)
