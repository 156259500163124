<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="this.$route.path"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive && isRouterAlive" :key="this.$route.path"></router-view>
<!--    <loading v-show="isShowLoading"></loading>-->
  </div>
</template>

<script>
import Loading from '@/components/loading/index.vue'

export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload, // 刷新界面
    }
  },
  components: {
    Loading
  },
  data () {
    return {
      isRouterAlive: true,
    }
  },
  computed: {
    isShowLoading(){
      return this.$store.state.isShowLoading
    },
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "assets/css/base.css";
  * {
    -webkit-user-select:none;
    user-select:none;
  }
  #app{
    transform-origin: left top 0px;
    transform: scale(0.366667);
  }
</style>

