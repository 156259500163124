import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //  redirect: 'report/SRsD1v7HziE8_Wgg' // 通用版本报告 ?csr=None&category=2#/report/SR5LffSqNKfbVHlg
  //  // redirect: 'facereport/SFRKL_Q5VYrE_c0g' // 单独面诊报告
  //  // redirect: 'tonguereport/STHtMf63AZfZB54w' // 单独面诊报告
  //  // redirect: 'pulse/1637550614' // 单独脉诊报告
  // },
  {
    path: '/report/:id',
    name: 'Report',
    component: () => import('@/views/report/index'),
    meta: {'title': '检测结果', keepAlive: true},
  },
  {
    path: '/chronic/:id', // 慢病/高血压报告
    name: 'Chronic',
    component: () => import('@/views/chronic/index'),
    meta: {'title': '检测结果', keepAlive: true},
  },
  {
    path: '/pulse/:id',
    name: 'Pulse',
    component: () => import('@/views/report/pulse/pulseresult'),
    meta: {'title': '脉诊结果', keepAlive: true},
  },
  {
    path: '/menopauseovary/:id',
    name: 'Menopauseovary',
    component: () => import('@/views/menopauseovary/index'),
    meta: {'title': '检测结果', keepAlive: true},
  },
  {
    path: '/aboutus',
    name: 'Aboutus',
    component: () => import('@/views/report/aboutus.vue'),
    meta: {'title': '检测结果', keepAlive: true},
  },
  {
    path: '/history/:id',
    name: 'History',
    component: () => import('@/views/report/index'),
    meta: {'title': '检测结果', keepAlive: true},
  },
  {
    path: '/constitution',
    name: 'Constitution',
    component: () => import('@/views/report/constitution'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/femaleexplain',
    name: 'Femaleexplain',
    component: () => import('@/views/menopauseovary/femaleexplain'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/outline',
    name: 'Outline',
    component: () => import('@/views/report/outline'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/femaleoutline',
    name: 'Femaleoutline',
    component: () => import('@/views/menopauseovary/femaleoutline'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/facereport/:id',
    name: 'Facereport',
    component: () => import('@/views/report/facereport'),
    meta: {'title': '个人健康管理面诊报告'},
    hidden: true
  },
  {
    path: '/tonguereport/:id',
    name: 'Tonguereport',
    component: () => import('@/views/report/tonguereport'),
    meta: {'title': '个人健康管理舌诊报告'},
    hidden: true
  },
  {
    path: '/labdetail',
    name: 'Labdetail',
    component: () => import('@/views/report/labdetail'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/knowledgebase',
    name: 'Knowledgebase',
    component: () => import('@/views/report/knowledgebase'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/healthprogram',
    name: 'Healthprogram',
    component: () => import('@/views/report/healthprogram'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/physique',
    name: 'Physique',
    component: () => import('@/views/menopauseovary/physique'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/facial',
    name: 'Facial',
    component: () => import('@/views/menopauseovary/facial'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/diagnosis',
    name: 'Diagnosis',
    component: () => import('@/views/menopauseovary/diagnosis'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('@/views/report/product'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/wares',
    name: 'Wares',
    component: () => import('@/views/report/wares'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/print/:id',
    name: 'Print',
    component: () => import('@/views/print/index'),
    meta: {'title': '打印结果', keepAlive: true},
  },
  {
    path: '/chronic_print/:id',
    name: 'Chronic_print',
    component: () => import('@/views/print/chronic'),
    meta: {'title': '慢病-打印结果', keepAlive: true},
  },
  {
    path: '/pulse_print/:id',
    name: 'Pulse_print',
    component: () => import('@/views/print/pulse/pulse'),
    meta: {'title': '脉诊-打印结果', keepAlive: true},
  },
  {
    path: '/reporttzbs/:id',
    name: 'Reporttzbs',
    component: () => import('@/views/physical/index'),
    meta: {'title': '个人体质健康管理报告', keepAlive: true},
    hidden: true
  },
  {
    path: '/healthplantzbs', // 体质养生方案
    name: 'Healthplantzbs',
    component: () => import('@/views/physical/healthplantzbs'),
    meta: {'title': '养生方案'},
    hidden: true
  },
  {
    path: '/healthchronic',  // 慢病养生方案
    name: 'Healthchronic',
    component: () => import('@/views/chronic/components/health'),
    meta: {'title': '养生方案'},
    hidden: true
  },
  {
    path: '/producttzbs',
    name: 'Producttzbs',
    component: () => import('@/views/physical/producttzbs'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/productdetailtzbs',
    name: 'Productdetailtzbs',
    component: () => import('@/views/physical/productdetailtzbs'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/knowledgetzbs',
    name: 'Knowledgetzbs',
    component: () => import('@/views/physical/knowledgetzbs'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/parsingtzbs',
    name: 'Parsingtzbs',
    component: () => import('@/views/physical/parsingtzbs'),
    meta: {'title': '个人健康管理报告'},
    hidden: true
  },
  {
    path: '/wxtzbs/:id', // http://localhost:8081/?category=2#/wxtzbs/SRDyIfEOcL-QLCRQ
    name: 'wxtzbs',
    component: () => import('@/views/wxreport/wxtzbs.vue'),
    meta: {'title': '手机版-体质辨识报告'},
  },
  {
    path: '/wxhealthy/:id',
    name: 'wxhealthy',
    component: () => import('@/views/wxreport/wxhealthy.vue'),
    meta: {'title': '手机版-健康状态报告'},
  },
  {
    path: '/wxfemale/:id',
    name: 'wxfemale',
    component: () => import('@/views/wxreport/wxfemale.vue'),
    meta: {'title': '手机版-女性健康报告'},
  },
  {
    path: '/wxchronic/:id',
    name: 'wxchronic',
    component: () => import('@/views/wxreport/wxchronic.vue'),
    meta: {'title': '手机版-慢病管理报告'},
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 导航守卫
router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title; // 修改文档标题
  next(); // 路由放行
})

export default router
