var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loading", attrs: { id: "loading" } },
    [
      _c(
        "van-loading",
        {
          attrs: {
            color: "#fff",
            size: "110px",
            "text-size": "32px",
            vertical: ""
          }
        },
        [_vm._v("努力加载中...")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }