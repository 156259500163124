<template>
  <div id="loading" class="loading">
    <van-loading color="#fff" size="110px" text-size="32px" vertical>努力加载中...</van-loading>
  </div>
</template>

<script>

export default {
  name: 'loading',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>
 
<style scoped>
.loading {
  left: 0;
  top: 0;
  position: fixed;
  padding-top: 80%;
  padding-left: 42%;
}
</style>