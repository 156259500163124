// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!./nomalize.css");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/TITLE.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/*:root{*/\n/*  --color-text: #666;*/\n/*  --color-high-text: #ff5777;*/\n/*  --color-tint: #ff8198;*/\n/*  --font-size: 16px;*/\n/*  --line-height: 1.5;*/\n/*}*/\n\n/*p{*/\n/*  font-size: var(--font-size);*/\n/*}*/\n*{\n  padding: 0;\n  margin: 0;\n}\n@media print {\n#app {\n    transform: scale(1) !important;\n}\n}\n@font-face {\n  font-family: 'TITLE';   /*字体名称*/\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n\n\n", ""]);
// Exports
module.exports = exports;
