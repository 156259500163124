// 获取路径里面拼接在?后面的参数，例如{ csr: 'ZHIYUN' }

export function queryObject() {
  try {
    const search = window.location.search.substring(1);
    if (!search || !search.length) {
      return {};
    }
    return JSON.parse(`{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`);
  } catch (e) {
    console.warn(e);
    return {};
  }
}


// 计算报告bmi值
export const calculateBMI = (height, weight) => {
  const bmi1 = weight / (height / 100.0) / (height / 100.0);
  let text = '无';
  if (bmi1 < 18.5) {
    text = '过轻';
  } else if (bmi1 < 24) {
    text = '正常';
  } else if (bmi1 < 28) {
    text = '超重';
  } else if (bmi1 <= 32) {
    text = '肥胖';
  } else if (bmi1 > 32){
    text = '非常肥胖';
  } 
  let bmi = bmi1.toFixed(2);
  if(bmi === 'NaN') {
    bmi = 0
  }
  return {
    bmi, text
  };
};
